import { gsap } from "@/gsap/GsapLoader";

export class CommonSection {
  constructor(el) {
    this.el = el;
    this.title = el.querySelectorAll("[data-section-title]");
    this.sub_title = el.querySelectorAll("[data-section-subtitle]");
    this.content = el.querySelectorAll("[data-section-content]");
    this.cards = el.querySelectorAll("[data-section-card]");

    this.timeline = null;
  }

  reset() {
    gsap.set(this.title, { opacity: 0, y: "30px" });
    gsap.set(this.sub_title, { opacity: 0, y: "30px" });
    gsap.set(this.content, { opacity: 0 });
    gsap.set(this.cards, { opacity: 0, y: "30px" });
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = null;
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: "top 70%",
        toggleActions: "restart play play reverse",
      },
    });

    this.timeline.fromTo(
      this.title,
      {
        opacity: 0,
        y: "30px",
      },
      {
        opacity: 1,
        y: "0px",
      },
      "image_in"
    );
    this.timeline.fromTo(
      this.sub_title,
      {
        opacity: 0,
        y: "30px",
      },
      {
        opacity: 1,
        delay: 0.3,
        y: "0px",
      },
      "image_in"
    );
    this.timeline.fromTo(
      this.content,
      {
        opacity: 0,
        y: "30px",
        // blur: 10,
      },
      {
        opacity: 1,
        y: "0px",
        delay: 0.3,
        // blur: 0,
      },
      "image_in"
    );
    this.timeline.fromTo(
      this.cards,
      {
        opacity: 0,
        y: "30px",
      },
      {
        opacity: 1,
        y: "0px",
        delay: 0.3,
        stagger: 0.2,
      },
      "image_in"
    );
  }
}
