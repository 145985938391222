<template>
  <aside
    :class="{
      '-translate-x-full': menu_status,
      'lg:top-[112px] top-[102px]': announcement_list.length > 0,
      'lg:top-[80px] top-[70px]': announcement_list.length <= 0,
    }"
    class="fixed bottom-0 z-30 w-full overflow-x-hidden overflow-y-auto transition-all duration-500 transform bg-white left-full"
  >
    <div class="relative z-0 flex flex-col w-full h-full">
      <ol class="flex-1 w-full pt-5">
        <li
          class="w-full px-5"
          v-for="(item, item_index) in menu_list"
          :key="`item_${item_index}`"
        >
          <router-link
            @click.native="$emit('menu-action')"
            class="flex items-center justify-between py-4 transition-all duration-300 border-b border-zinc-300"
            :to="item.link"
          >
            <div class="text-xs font-medium uppercase text-primary">
              <span class="block text-lg font-bold text-black uppercase">{{
                item.title
              }}</span>
              {{ item.sub_title }}
            </div>

            <span
              class="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
            >
              <span class="text-xs icon-chevron-right"></span>
            </span>
          </router-link>
        </li>

        <li class="w-full px-5 mt-10">
          <div class="pb-4 border-b border-zinc-300">
            <p class="text-lg font-bold text-black uppercase">語言選擇</p>
            <p class="mb-4 text-xs font-medium text-primary">LANGUAGE</p>

            <div class="flex items-center">
              <button
                class="px-3 py-1 mr-4 text-sm font-bold text-white border rounded-full bg-primary border-primary"
              >
                繁體中文
              </button>
              <a
                href="https://www.roadwheel.com.tw/en/?lang=true"
                class="px-3 py-1 mr-5 text-sm font-bold bg-transparent border rounded-full border-primary text-primary"
              >
                ENGLISH
              </a>
            </div>
          </div>
        </li>
      </ol>
      <!-- <div class="px-5 pb-5">
        <router-link
          to="/account/account_edit"
          @click.native="$emit('menu-action')"
          v-if="CheckLogin()"
          class="flex items-center justify-between w-full py-3 pl-6 pr-3 text-sm font-medium text-center text-white transition-all duration-300 border rounded-full bg-primary md:hover:bg-opacity-80 border-primary"
        >
          會員中心

          <span
            class="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
          >
            <span class="text-xs icon-chevron-right"></span>
          </span>
        </router-link>

        <router-link
          to="/account/login"
          @click.native="$emit('menu-action')"
          v-else
          class="flex items-center justify-between w-full py-3 pl-6 pr-3 text-sm font-medium text-center text-black transition-all duration-300 bg-transparent border rounded-full md:hover:bg-primary md:hover:text-white border-primary"
        >
          登入 / 註冊

          <span
            class="flex items-center justify-center w-6 h-6 text-white rounded-full bg-primary"
          >
            <span class="text-xs icon-chevron-right"></span>
          </span>
        </router-link>
      </div> -->
    </div>
  </aside>
</template>

<script>
import { getLocalStorage } from "@/common/cookie";
export default {
  name: "MainMenu",
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  data() {
    return {
      menu_list: [
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "關於我們",
          sub_title: "About Us",
          link: "/about",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "影音專區",
          sub_title: "Videos",
          link: "/video",
        },
        {
          title: "聯絡我們",
          sub_title: "Contact",
          link: "/contact",
        },
      ],
    };
  },
  methods: {
    CheckLogin() {
      return getLocalStorage("account_token") != null;
    },
  },
  computed: {
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
};
</script>
