<template>
  <section class="relative z-10 w-full">
    <div ref="GlideSlider" class="glide">
      <div class="relative glide__track" data-glide-el="track">
        <ul class="relative z-0 glide__slides">
          <li
            class="relative w-full glide__slide"
            v-for="(item, item_index) in carousel_data"
            :key="`carousel_${item_index}`"
          >
            <a
              v-if="item.Link != ''"
              :href="item.Link"
              class="relative block w-full overflow-hidden aspect-[1/1.5] md:aspect-[2.5/1]"
            >
              <img
                :src="$ImageUrl(item.Image1)"
                :alt="$GetColumn('company_name')"
                class="hidden object-cover w-full h-full md:block"
              />
              <img
                :src="$ImageUrl(item.Image2)"
                :alt="$GetColumn('company_name')"
                class="block object-cover w-full h-full md:hidden"
              />
            </a>
            <div
              v-else
              class="relative block w-full overflow-hidden aspect-[1/1.5] md:aspect-[2.5/1]"
            >
              <img
                :src="$ImageUrl(item.Image1)"
                :alt="$GetColumn('company_name')"
                class="hidden object-cover w-full h-full md:block"
              />
              <img
                :src="$ImageUrl(item.Image2)"
                :alt="$GetColumn('company_name')"
                class="block object-cover w-full h-full md:hidden"
              />
            </div>
          </li>
        </ul>

        <div
          class="absolute left-0 z-10 flex items-center justify-center w-full bottom-5 glide__bullets"
          data-glide-el="controls[nav]"
        >
          <button
            class="w-2 h-2 mx-1 bg-white rounded-full glide__bullet"
            :data-glide-dir="`=${item_index}`"
            v-for="(item, item_index) in carousel_data"
            :key="`bullet_${item_index}`"
          ></button>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
.glide__bullet {
  opacity: 0.5;
}
.glide__bullet.glide__bullet--active {
  opacity: 1;
}
</style>

<script>
import "@glidejs/glide/dist/css/glide.core.min.css";
import Glide from "@glidejs/glide";
export default {
  name: "HomeCarousel",
  data() {
    return {
      glide_slider: null,
    };
  },
  computed: {
    carousel_data() {
      return this.$store.getters.home_cover_carousel;
    },
    lock_body_scroll() {
      return this.$store.state.lock_body_scroll;
    },
  },
  methods: {
    SetGsap() {
      this.glide_slider = new Glide(this.$refs.GlideSlider, {
        autoplay: 5000,
        gap: 0,
      });
      this.glide_slider.mount();
    },
    PreLoadImage() {
      if (window.innerWidth > 768) {
        this.$PreLoadImage(this.$ImageUrl(this.carousel_data[0].Image1));
      } else {
        this.$PreLoadImage(this.$ImageUrl(this.carousel_data[0].Image2));
      }
    },
  },
  watch: {
    lock_body_scroll() {
      this.$nextTick(() => {
        this.glide_slider != null ? this.glide_slider.update() : "";
      });
    },
  },
  mounted() {
    this.PreLoadImage();
  },
};
</script>
