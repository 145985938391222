<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full py-20"
    :class="image_loaded ? 'opacity-100' : 'opacity-0'"
  >
    <div
      class="relative z-10 flex flex-wrap items-stretch w-full max-w-screen-xl px-5 mx-auto xl:px-0"
    >
      <header
        data-section-content
        class="relative flex flex-row items-center justify-between w-full pr-5 mb-10 md:items-start md:justify-start md:w-1/4 md:flex-col md:mb-0"
      >
        <div class="md:mb-10">
          <p class="mb-1 text-4xl font-bold md:text-5xl">NEWS</p>
          <h3 class="font-bold md:text-xl">最新消息</h3>
        </div>

        <router-link
          @mouseenter.native="button_hover = true"
          @mouseleave.native="button_hover = false"
          to="/news"
          class="flex items-center justify-between py-3 pl-6 pr-3 text-sm font-medium text-center text-white transition-all duration-300 border rounded-full w-44 bg-primary md:hover:text-primary md:hover:bg-white border-primary"
        >
          所有文章

          <span
            :class="
              button_hover ? 'bg-primary text-white' : 'bg-white text-primary'
            "
            class="flex items-center justify-center w-6 h-6 transition-all duration-300 rounded-full"
          >
            <span class="text-xs icon-chevron-right"></span>
          </span>
        </router-link>
      </header>

      <ol class="w-full p-5 bg-white md:p-10 md:w-3/4">
        <li
          data-section-card
          v-for="(item, item_index) in news_data"
          :key="`news_${item_index}`"
          class="w-full"
        >
          <router-link
            :to="`/news/${item.NewsID}`"
            class="flex items-center justify-between pb-5 mb-5 border-b border-zinc-300 md:hover:text-zinc-500"
          >
            <div>
              <div class="flex items-center w-full mb-4">
                <p class="mr-5 text-zinc-500">
                  {{ item.created_at.slice(0, 10) }}
                </p>
                <p
                  class="px-3 py-1 text-xs border rounded-full border-primary text-primary"
                >
                  {{ item.CategoryTitle }}
                </p>
              </div>
              <h4 class="font-medium transition-all duration-500">
                {{ item.Title }}
              </h4>
            </div>
            <span
              class="items-center justify-center hidden w-6 h-6 text-white rounded-full md:flex bg-primary"
            >
              <span class="text-xs icon-chevron-right"></span>
            </span>
          </router-link>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
import { CommonSection } from "@/gsap/CommonSection.js";
export default {
  name: "NewsListSection",
  props: {
    image_loaded: {
      require: true,
      type: Boolean,
    },
  },
  data() {
    return {
      button_hover: false,
      section_gsap: null,
    };
  },
  computed: {
    news_data() {
      return this.$store.state.news_data.slice(0, 4);
    },
  },
  methods: {
    GetContent(val) {
      var msg = val.replace(/<\/?[^>]*>/g, ""); //去除HTML Tag
      msg = msg.replace(/[|]*\n/, ""); //去除行尾空格
      msg = msg.replace(/&npsp;/gi, ""); //去掉npsp

      return msg.slice(0, 50) + "...";
    },
    SetGsap() {
      this.section_gsap = new CommonSection(this.$refs.MainContent);
      this.section_gsap.reset();
      this.section_gsap.setup();
    },
  },
};
</script>