<template>
  <main class="relative w-full overflow-x-hidden">
    <Carousel ref="Carousel" />

    <About ref="AboutSection" :image_loaded="image_loaded" />

    <div ref="PromoteSection">
      <router-link
        data-section-content
        to="/collections"
        class="block w-full max-w-screen-xl px-5 py-20 mx-auto xl:px-0"
      >
        <img
          :src="$ImageUrl($GetColumn('home_promote_image_pc'))"
          class="hidden w-full md:block"
        />
        <img
          :src="$ImageUrl($GetColumn('home_promote_image_mb'))"
          class="block w-full md:hidden"
        />
      </router-link>
    </div>

    <ProductList ref="ProductList" :image_loaded="image_loaded" />

    <CustomizeInspired ref="FactorySection" :image_loaded="image_loaded" />
    <NewsList ref="NewsList" :image_loaded="image_loaded" />
  </main>
</template>

<script>
import Carousel from "@/components/home/Carousel.vue";
import ProductList from "@/components/home/ProductList.vue";
import About from "@/components/home/About.vue";
import CustomizeInspired from "@/components/home/CustomizeInspired.vue";
import NewsList from "@/components/home/NewsList.vue";
import { CommonSection } from "@/gsap/CommonSection.js";

export default {
  name: "HomeView",
  components: {
    Carousel,
    About,
    ProductList,
    CustomizeInspired,
    NewsList,
  },
  data() {
    return {
      section_gsap: null,
    };
  },
  computed: {
    image_loaded() {
      return this.$store.state.image_loaded;
    },
  },
  watch: {
    image_loaded() {
      if (this.image_loaded) {
        this.$refs.Carousel.SetGsap();
        this.$refs.AboutSection.SetGsap();
        this.$refs.ProductList.SetGsap();
        this.$refs.FactorySection.SetGsap();
        this.$refs.NewsList.SetGsap();

        this.section_gsap = new CommonSection(this.$refs.PromoteSection);
        this.section_gsap.reset();
        this.section_gsap.setup();
      }
    },
  },
  created() {
    this.$PageInit(
      this,
      this.$GetColumn("meta_title_home"),
      this.$GetColumn("meta_content_home"),
      this.$GetColumn("meta_image_home") != ""
        ? this.$ImageUrl(this.$GetColumn("meta_image_home"))
        : ""
    );
  },
};
</script>
