<template>
  <div
    ref="MainContent"
    class="fixed top-0 z-50 flex items-center justify-center w-full h-full left-full backdrop-blur-lg"
  >
    <div data-dialog-box class="relative z-10">
      <div
        class="flex flex-wrap items-stretch justify-start w-full max-w-screen-lg px-10 mx-auto overflow-hidden md:px-0 rounded-xl"
      >
        <div
          class="w-full mb-5 overflow-hidden cursor-pointer select-none md:w-1/2 md:mb-0 md:rounded-none rounded-xl"
          @mouseenter="hover_index = 1"
          @mouseleave="hover_index = -1"
          @click="dialog = false"
        >
          <div class="w-full overflow-hidden aspect-video">
            <img
              src="@/assets/img/1706604992_8d935ed69efcb2b0da76.webp"
              class="object-cover w-full h-full transition-all"
              :class="
                hover_index == 1 ? 'scale-110 duration-300' : 'duration-500'
              "
            />
          </div>
          <div
            class="flex items-center justify-between px-5 py-4 transition-all duration-300 md:block md:text-center md:px-20 md:py-10"
            :class="hover_index == 1 ? 'bg-primary text-white' : 'bg-white'"
          >
            <p
              class="text-2xl font-bold transition-all duration-500 md:mb-5 md:text-4xl"
              :class="hover_index == 1 ? 'text-white' : 'text-primary'"
            >
              中文版
            </p>
            <div
              class="flex items-center justify-center text-sm font-bold md:text-base"
            >
              由此進入<span
                class="flex items-center justify-center w-5 h-5 ml-3 transition-all duration-300 rounded-full"
                :class="
                  hover_index == 1
                    ? 'bg-white text-primary'
                    : 'bg-primary text-white'
                "
              >
                <span class="text-xs icon-chevron-right"></span>
              </span>
            </div>
          </div>
        </div>
        <a
          class="w-full mb-5 overflow-hidden md:w-1/2 md:mb-0 md:rounded-none rounded-xl"
          @mouseenter="hover_index = 2"
          @mouseleave="hover_index = -1"
          href="https://www.roadwheel.com.tw/en/?lang=true"
        >
          <div class="w-full overflow-hidden aspect-video">
            <img
              src="@/assets/img/1706605031_b2857f6c69c583794169.webp"
              class="object-cover w-full h-full transition-all"
              :class="
                hover_index == 2 ? 'scale-110 duration-300' : 'duration-500'
              "
            />
          </div>
          <div
            class="flex items-center justify-between px-5 py-4 transition-all duration-300 md:block md:text-center md:px-20 md:py-10"
            :class="hover_index == 2 ? 'bg-primary text-white' : 'bg-white'"
          >
            <p
              class="text-2xl font-bold transition-all duration-500 md:mb-5 md:text-4xl"
              :class="hover_index == 2 ? 'text-white' : 'text-primary'"
            >
              ENGLISH
            </p>
            <div
              class="flex items-center justify-center text-sm font-bold md:text-base"
            >
              ENTER HERE<span
                class="flex items-center justify-center w-5 h-5 ml-3 transition-all duration-300 rounded-full"
                :class="
                  hover_index == 2
                    ? 'bg-white text-primary'
                    : 'bg-primary text-white'
                "
              >
                <span class="text-xs icon-chevron-right"></span>
              </span>
            </div>
          </div>
        </a>
      </div>
      <div class="flex items-center justify-center pt-5">
        <button
          @click="dialog = false"
          class="px-10 py-2 font-bold text-white transition-all duration-500 border border-white rounded-full hover:bg-white hover:text-black"
        >
          CLOSE
        </button>
      </div>
    </div>
    <div
      data-dialog-background
      class="absolute top-0 left-0 z-0 w-full h-full bg-black bg-opacity-70"
    ></div>
  </div>
</template>

<script>
import { Dialog } from "@/gsap/MainDialog";
export default {
  name: "LanguageDialog",
  data() {
    return {
      hover_index: -1,
      dialog: false,
      dialog_gsap: null,
    };
  },
  methods: {
    Open() {
      this.dialog = true;
    },
  },
  watch: {
    dialog() {
      this.dialog ? this.dialog_gsap.open() : this.dialog_gsap.close();
    },
  },
  mounted() {
    this.dialog_gsap = new Dialog(this.$refs.MainContent);
    this.dialog_gsap.reset();
  },
};
</script>