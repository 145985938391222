<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full overflow-hidden bg-black"
    :class="image_loaded ? 'opacity-100' : 'opacity-0'"
  >
    <div class="relative w-full max-w-screen-xl px-5 py-20 mx-auto xl:px-0">
      <div
        class="relative z-10 flex flex-col-reverse items-center w-full md:flex-row"
      >
        <div data-section-content class="w-full text-center md:w-1/2">
          <img
            :src="$ImageUrl($GetColumn('home_factory_image'))"
            class="block w-full"
          />
        </div>
        <div class="w-full mb-10 text-white md:mb-0 md:w-1/2 md:pl-10">
          <div class="mb-2 overflow-hidden">
            <p data-section-subtitle class="font-bold text-primary">
              {{ $GetColumn("home_factory_sub_title") }}
            </p>
          </div>
          <div class="mb-5 overflow-hidden">
            <h3 data-section-title class="text-3xl font-bold lg:text-5xl">
              {{ $GetColumn("home_factory_title") }}
            </h3>
          </div>
          <div data-section-content class="text-sm leading-loose">
            <p v-html="$GetColumn('home_factory_content')"></p>
          </div>
        </div>
      </div>
      <div
        class="absolute top-0 right-0 w-full md:w-1/2 md:h-full h-1/2 z-[5] md:bg-gradient-to-l bg-gradient-to-b from-black to-transparent"
      ></div>

      <div
        class="absolute top-0 bottom-0 left-0 right-0 z-0 flex justify-end px-5 opacity-30 lg:px-0"
      >
        <div class="w-1/2 mr-5 md:w-1/3 lg:w-72">
          <ol class="gallery_marquee">
            <li
              v-for="(item, item_index) in carousel_data"
              :key="`carousel_1_1_${item_index}`"
              class="relative w-full pb-5"
            >
              <div class="w-full aspect-video">
                <img :src="$ImageUrl(item.Image1)" class="w-full" />
              </div>
            </li>
          </ol>
          <ol class="gallery_marquee second">
            <li
              v-for="(item, item_index) in carousel_data"
              :key="`carousel_1_2_${item_index}`"
              class="relative w-full pb-5 aspect-video"
            >
              <div class="w-full aspect-video">
                <img :src="$ImageUrl(item.Image1)" class="w-full" />
              </div>
            </li>
          </ol>
        </div>
        <div class="w-1/2 transform -translate-y-20 md:w-1/3 md:mr-5 lg:w-72">
          <ol class="gallery_marquee_reverse">
            <li
              v-for="(item, item_index) in carousel_data"
              :key="`carousel_1_1_${item_index}`"
              class="relative w-full pb-5"
            >
              <div class="w-full aspect-video">
                <img :src="$ImageUrl(item.Image1)" class="w-full" />
              </div>
            </li>
          </ol>
          <ol class="gallery_marquee_reverse second">
            <li
              v-for="(item, item_index) in carousel_data"
              :key="`carousel_1_2_${item_index}`"
              class="relative w-full pb-5 aspect-video"
            >
              <div class="w-full aspect-video">
                <img :src="$ImageUrl(item.Image1)" class="w-full" />
              </div>
            </li>
          </ol>
        </div>
        <div class="hidden w-1/3 md:block lg:w-72">
          <ol class="transform -translate-y-full gallery_marquee">
            <li
              v-for="(item, item_index) in carousel_data"
              :key="`carousel_1_1_${item_index}`"
              class="relative w-full pb-5"
            >
              <div class="w-full aspect-video">
                <img :src="$ImageUrl(item.Image1)" class="w-full" />
              </div>
            </li>
          </ol>
          <ol class="gallery_marquee second">
            <li
              v-for="(item, item_index) in carousel_data"
              :key="`carousel_1_2_${item_index}`"
              class="relative w-full pb-5 aspect-video"
            >
              <div class="w-full aspect-video">
                <img :src="$ImageUrl(item.Image1)" class="w-full" />
              </div>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CommonSection } from "@/gsap/CommonSection.js";
export default {
  name: "CustomizeInspiredSection",
  props: {
    image_loaded: {
      require: true,
      type: Boolean,
    },
  },
  data() {
    return {
      section_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap = new CommonSection(this.$refs.MainContent);
      this.section_gsap.reset();
      this.section_gsap.setup();
    },
  },
  computed: {
    carousel_data() {
      return this.$store.getters.home_gallery_carousel;
    },
  },
};
</script>

<style scoped>
.gallery_marquee {
  animation: gallery_marquee 60s -30s linear infinite;
}
.gallery_marquee.second {
  animation: gallery_marquee_2 60s 0s linear infinite;
}

.gallery_marquee_reverse {
  animation: gallery_marquee 60s 0s infinite linear reverse;
}
.gallery_marquee_reverse.second {
  animation: gallery_marquee_2 60s -30s infinite linear reverse;
}

@keyframes gallery_marquee {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}
@keyframes gallery_marquee_2 {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-200%);
  }
}
</style>