<template>
  <section
    ref="MainContent"
    class="relative z-10 w-full mb-20"
    :class="image_loaded ? 'opacity-100' : 'opacity-0'"
  >
    <div class="w-full max-w-screen-xl px-5 mx-auto xl:px-0">
      <header class="relative w-full mb-5">
        <p data-section-title class="mb-1 text-4xl font-bold md:text-5xl">
          PRODUCT SERIES
        </p>
        <h3 data-section-subtitle class="mb-10 font-bold md:text-xl">
          商品分類
        </h3>
      </header>

      <div class="flex flex-wrap items-stretch -mx-2">
        <div
          data-section-card
          v-for="(category, category_index) in product_category_data"
          :key="`category_${category_index}`"
          class="w-full px-2 mb-4 md:w-1/2 lg:w-1/4"
        >
          <div class="relative w-full h-full">
            <router-link
              :to="category.Link"
              @mouseenter.native="hover_card_index = category_index"
              @mouseleave.native="hover_card_index = -1"
              class="relative z-10 block h-full p-6 transition-all duration-500 transform bg-white hover:text-primary"
            >
              <div
                class="w-full mb-10 overflow-hidden aspect-video md:aspect-square"
              >
                <img
                  :src="$ImageUrl(category.Image)"
                  class="object-contain w-full h-full transition-all duration-500 transform"
                  :class="
                    hover_card_index == category_index
                      ? 'scale-110'
                      : 'scale-100'
                  "
                />
              </div>
              <div class="flex items-center justify-between">
                <div class="mr-5">
                  <h4 class="text-xl font-medium">{{ category.Title }}</h4>
                  <p class="text-sm font-medium uppercase text-primary">
                    {{ category.SubTitle }}
                  </p>
                </div>
                <span
                  class="flex items-center justify-center flex-shrink-0 w-6 h-6 text-white rounded-full bg-primary"
                >
                  <span class="text-xs icon-chevron-right"></span>
                </span>
              </div>
            </router-link>

            <span
              class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-primary"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CommonSection } from "@/gsap/CommonSection.js";
export default {
  name: "ProductListSection",
  props: {
    title: {
      type: String,
    },
    image_loaded: {
      type: Boolean,
    },
  },
  data() {
    return {
      hover_card_index: -1,
      section_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap = new CommonSection(this.$refs.MainContent);
      this.section_gsap.reset();
      this.section_gsap.setup();
    },
  },
  computed: {
    product_category_data() {
      return this.$store.getters.home_product_category;
    },
  },
};
</script>
