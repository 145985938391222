<template>
  <div
    ref="MainContent"
    class="fixed top-0 z-30 flex items-center justify-center w-full h-screen left-full"
  >
    <div
      data-dialog-box
      class="relative z-10 w-11/12 max-w-md px-5 bg-white py-14"
    >
      <p
        class="mb-10 font-bold text-center text-primary"
        v-html="dialog_content"
      ></p>
      <div class="flex justify-center w-full">
        <button
          @mouseenter="button_hover = true"
          @mouseleave="button_hover = false"
          @click="Close"
          to="/"
          class="flex items-center justify-between py-3 pl-6 pr-3 text-sm font-medium text-center text-white transition-all duration-300 border rounded-full w-44 bg-primary md:hover:text-primary md:hover:bg-white border-primary"
        >
          關閉

          <span
            :class="
              button_hover ? 'bg-primary text-white' : 'bg-white text-primary'
            "
            class="flex items-center justify-center w-6 h-6 transition-all duration-300 rounded-full"
          >
            <span class="text-xs icon-close"></span>
          </span>
        </button>
      </div>
    </div>
    <div
      data-dialog-background
      class="absolute top-0 bottom-0 left-0 right-0 z-0 bg-black bg-opacity-50"
    ></div>
  </div>
</template>

<script>
import { Dialog } from "@/gsap/MainDialog";
export default {
  name: "MainDialog",
  data() {
    return {
      dialog_gsap: null,
      button_hover: false,
    };
  },
  methods: {
    Close() {
      this.$store.commit("SetDialog", {
        status: false,
        content: this.dialog_content,
      });
    },
  },
  watch: {
    dialog_status() {
      this.dialog_status ? this.dialog_gsap.open() : this.dialog_gsap.close();
    },
  },
  computed: {
    dialog_status() {
      return this.$store.state.main_dialog.status;
    },
    dialog_content() {
      return this.$store.state.main_dialog.content;
    },
  },
  mounted() {
    this.dialog_gsap = new Dialog(this.$refs.MainContent);
    this.dialog_gsap.reset();
  },
};
</script>
