import { gsap, ScrollTrigger } from "@/gsap/GsapLoader";
import imagesloaded from "imagesloaded";
import store from "@/store/index";

export class ImageLoader {
  LoadImage() {
    store.commit("SetImageLoaded", false);
    store.commit("SetLoading", 1);
    store.commit("SetLockBodyScroll", 1);
    const images = gsap.utils.toArray("img");
    imagesloaded(images).on("always", () => {
      this.LoadFinish();
    });
  }

  LoadFinish() {
    //設定讀取完成
    store.commit("SetImageLoaded", true);
    console.log("all images have been loaded!");
    store.commit("SetLockBodyScroll", -1);

    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 150);

    store.commit("SetLoading", -1);
  }
}
