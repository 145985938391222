import store from "@/store/index";
import router from "@/router/index";

export function GetBaseMetaData() {
  let meta_data_list = store.state.common_column_data;
  let base_title = meta_data_list.filter(
    (item) => item.Title == "meta_title_home"
  )[0];
  let base_content = meta_data_list.filter(
    (item) => item.Title == "meta_content_home"
  )[0];
  let base_image = meta_data_list.filter(
    (item) => item.Title == "meta_image_home"
  )[0];
  return {
    title: base_title.Content,
    content: base_content.Content.replaceAll(/<[^>]+>/g, ""),
    image: GetImageUrl(base_image.Content),
  };
}

export function GetMetaData(title, content = "", image = "") {
  let default_meta = GetBaseMetaData();
  default_meta.content.length > 155
    ? (default_meta.content = default_meta.content.slice(0, 155) + "...")
    : "";
  return {
    title: title + " | 羽展工業股份有限公司 | Road Wheel Corporation",
    meta: [
      {
        property: "og:url",
        name: "og:url",
        content: process.env.VUE_APP_BASEURL + router.history.current.fullPath,
        vmid: "og:url",
      },
      {
        property: "og:title",
        name: "og:title",
        content: title + " | " + default_meta.title,
        vmid: "og:title",
      },
      {
        property: "og:description",
        name: "og:description",
        content: content == "" ? default_meta.content : content,
        vmid: "og:description",
      },
      {
        property: "twitter:description",
        name: "twitter:description",
        content: content == "" ? default_meta.content : content,
        vmid: "twitter:description",
      },
      {
        property: "description",
        name: "description",
        content: content == "" ? default_meta.content : content,
        vmid: "description",
      },
      {
        property: "og:image",
        name: "og:image",
        content: image == "" ? default_meta.image : image,
        vmid: "og:image",
      },
      {
        property: "apple-mobile-web-app-title",
        name: "apple-mobile-web-app-title",
        content: title + " | 羽展工業股份有限公司 | Road Wheel Corporation",
        vmid: "apple-mobile-web-app-title",
      },
      {
        property: "application-name",
        name: "application-name",
        content: title + " | 羽展工業股份有限公司 | Road Wheel Corporation",
        vmid: "application-name",
      },
      {
        property: "og:site_name",
        name: "og:site_name",
        content:
          "官方網站" + " | 羽展工業股份有限公司 | Road Wheel Corporation",
        vmid: "og:site_name",
      },
      {
        property: "og:type",
        name: "og:type",
        content: "website",
        vmid: "og:type",
      },
      {
        property: "og:locale",
        name: "og:locale",
        content: "zh_tw",
        vmid: "og:locale",
      },
    ],
  };
}

function GetImageUrl(item) {
  return item == "" ? "" : process.env.VUE_APP_BASE_API + item;
}
