<template>
  <section
    ref="MainContent"
    class="relative z-10"
    :class="image_loaded ? 'opacity-100' : 'opacity-0'"
  >
    <div class="w-full max-w-screen-xl px-5 pt-20 mx-auto md:pt-40 xl:px-0">
      <div class="flex flex-wrap items-stretch">
        <div class="w-full mb-10 md:mb-0 md:pr-10 md:w-1/2 lg:pr-20">
          <header class="">
            <p data-section-title class="mb-1 text-4xl font-bold md:text-5xl">
              WHO WE ARE
            </p>
            <h3 data-section-subtitle class="mb-10 font-bold md:text-xl">
              關於我們
            </h3>
          </header>

          <div data-section-content class="block w-full mb-5 md:hidden md:mb-0">
            <img
              :src="$ImageUrl($GetColumn('home_about_image'))"
              class="object-cover object-center w-full h-full"
            />
          </div>

          <div data-section-content>
            <div class="mb-10 text-sm leading-loose">
              <p v-html="$GetColumn('home_about_content')"></p>
            </div>

            <router-link
              @mouseenter.native="button_hover = true"
              @mouseleave.native="button_hover = false"
              to="/about"
              class="flex items-center justify-between py-3 pl-6 pr-3 text-sm font-medium text-center text-white transition-all duration-300 border rounded-full w-44 bg-primary md:hover:text-primary md:hover:bg-white border-primary"
            >
              關於我們

              <span
                :class="
                  button_hover
                    ? 'bg-primary text-white'
                    : 'bg-white text-primary'
                "
                class="flex items-center justify-center w-6 h-6 transition-all duration-300 rounded-full"
              >
                <span class="text-xs icon-chevron-right"></span>
              </span>
            </router-link>
          </div>
        </div>

        <div data-section-content class="hidden w-1/2 mb-0 md:block">
          <img
            :src="$ImageUrl($GetColumn('home_about_image'))"
            class="object-cover object-center w-full h-full"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { CommonSection } from "@/gsap/CommonSection.js";
export default {
  name: "HomeAboutSection",
  props: {
    image_loaded: {
      require: true,
      type: Boolean,
    },
  },
  data() {
    return {
      button_hover: false,
      section_gsap: null,
    };
  },
  methods: {
    SetGsap() {
      this.section_gsap = new CommonSection(this.$refs.MainContent);
      this.section_gsap.reset();
      this.section_gsap.setup();
    },
  },
};
</script>