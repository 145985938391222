<template>
  <div
    id="app"
    class="relative z-10 flex flex-col w-full min-h-screen bg-zinc-50"
  >
    <MainLoading />

    <ShopCartDrawer />
    <SearchDialog />
    <LanguageDialog ref="LanguageDialog" />

    <template v-if="all_data_loaded">
      <MainHeader
        :menu_status="menu_status"
        @menu-action="menu_status = !menu_status"
        @open-language="OpenLanguageDialog"
      />
      <MainMenu
        ref="MainMenu"
        :menu_status="menu_status"
        @menu-action="menu_status = !menu_status"
      />
      <MainDialog />

      <!-- 114 77 -->
      <div
        class="relative z-10 flex-1"
        :class="
          announcement_list.length > 0
            ? 'pt-[102px] lg:pt-[112px] '
            : 'lg:pt-[80px] pt-[70px]'
        "
      >
        <router-view
          class="relative z-10"
          :window_size="window_size"
          @set-meta="SetMetaData"
          @load-image="LoadImage"
          @open-language="OpenLanguageDialog"
        />
      </div>
      <ContactFooter />
      <MainFooter />
    </template>
  </div>
</template>

<script>
import { ImageLoader } from "@/gsap/ImageLoader";
import { GetMetaData } from "@/common/meta";
import MainDialog from "@/components/MainDialog.vue";
import MainLoading from "@/components/MainLoading.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import MainMenu from "@/components/MainMenu.vue";
import ContactFooter from "@/components/ContactFooter.vue";
import ShopCartDrawer from "@/components/ShopCartDrawer.vue";
import SearchDialog from "@/components/SearchDialog.vue";
import LanguageDialog from "./components/LanguageDialog.vue";
export default {
  name: "App",
  components: {
    LanguageDialog,
    MainDialog,
    MainLoading,
    MainHeader,
    MainFooter,
    MainMenu,
    ContactFooter,
    ShopCartDrawer,
    SearchDialog,
  },
  data() {
    return {
      image_loader: null,
      menu_status: false,
      meta_data: null,
      window_size: window.innerWidth,
    };
  },
  methods: {
    LoadImage() {
      // 確認頁面圖片讀取狀況
      this.$nextTick(() => {
        this.image_loader.LoadImage();
      });
    },
    SetMetaData(title, content, image) {
      this.meta_data = GetMetaData(title, content, image);
    },
    OpenLanguageDialog() {
      this.$refs.LanguageDialog.Open();
    },
    CheckFirstEnter() {
      if (
        this.first_enter_status &&
        this.$route.name == "home" &&
        this.$route.query.lang != "true"
      ) {
        this.$PreLoadImage(
          process.env.VUE_APP_BASEURL +
            "/img/1706604992_8d935ed69efcb2b0da76.webp"
        );
        this.$PreLoadImage(
          process.env.VUE_APP_BASEURL +
            "/img/1706605031_b2857f6c69c583794169.webp"
        );
        this.OpenLanguageDialog();
      }
      this.$store.commit("SetFirstEnterStatus", false);
    },
  },
  watch: {
    all_data_loaded() {
      if (this.all_data_loaded) {
        this.$store.dispatch("shopcart_module/GetShopCart");
      }
    },
    lock_body_scroll() {
      if (this.lock_body_scroll != 0) {
        document.querySelector("body").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "auto";
      }
    },
  },
  computed: {
    all_data_loaded() {
      return this.$store.getters.all_data_loaded;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
    first_enter_status() {
      return this.$store.state.first_enter_status;
    },
    lock_body_scroll() {
      return this.$store.state.lock_body_scroll;
    },
  },
  mounted() {
    this.image_loader = new ImageLoader();
    window.addEventListener("resize", () => {
      this.menu_status = false;
      this.window_size = window.innerWidth;
    });
    this.CheckFirstEnter();
  },
  created() {
    !this.all_data_loaded ? this.$store.dispatch("getAllData") : "";
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>

<style src="@/assets/css/output.css"></style>
