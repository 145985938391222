<template>
  <div class="fixed top-0 left-0 z-30 w-full">
    <div
      ref="AnnouncementMarquee"
      v-if="announcement_list.length > 0"
      class="relative z-20 w-full h-8 py-1 overflow-hidden text-sm font-medium text-center bg-zinc-800"
    >
      <p
        v-for="(item, item_index) in announcement_list"
        :key="`announcement_${item_index}`"
        :class="announcement_list.length == 1 ? 'top-0' : 'top-full'"
        class="absolute left-0 flex items-center justify-center w-full h-full overflow-hidden whitespace-nowrap"
      >
        <span v-if="item.link == ''">
          {{ item.content }}
        </span>
        <a
          v-else
          :href="item.link"
          target="_blank"
          :key="`announcement_${item_index}`"
          class="flex items-center justify-center w-full h-full overflow-hidden whitespace-nowrap"
        >
          {{ item.content }}
        </a>
      </p>
    </div>
    <div class="relative">
      <div
        class="w-full relative z-10 h-[70px] lg:h-[80px] bg-white lg:bg-zinc-50"
      >
        <div
          class="relative z-10 flex items-center justify-end w-full h-full max-w-screen-xl px-5 mx-auto lg:justify-between"
        >
          <router-link
            @click.native="CloseMenu"
            to="/"
            class="absolute z-10 flex items-center h-full transform -translate-y-1/2 lg:left-0 left-5 lg:translate-y-0 lg:relative lg:top-auto top-1/2"
          >
            <img src="@/assets/img/logo.webp" class="block w-16" />
          </router-link>

          <div class="flex items-center h-full">
            <ol class="items-center hidden h-full mr-5 lg:flex">
              <li
                v-for="(item, item_index) in menu_list"
                :key="`list_item_${item_index}`"
                class="flex items-center h-full"
              >
                <router-link
                  @mouseenter.native="hover_link_index = item_index"
                  @mouseleave.native="hover_link_index = -1"
                  class="relative flex items-center h-full px-5 text-sm font-bold transition-colors duration-300 md:hover:text-zinc-700"
                  :to="item.link"
                >
                  <span class="block">{{ item.title }}</span>
                  <span
                    class="absolute bottom-0 left-0 right-0 z-0 block w-full h-[2px] bg-primary transform origin-left transition-all duration-500"
                    :class="
                      hover_link_index == item_index
                        ? 'scale-x-100'
                        : 'scale-x-0'
                    "
                  ></span>
                </router-link>
              </li>
              <!-- <li class="flex items-center h-full">
                <button
                  class="relative flex items-center h-full px-5 text-sm font-bold transition-colors duration-300 text-primary md:hover:text-zinc-700"
                >
                  <span class="block mr-2">繁體中文</span>
                  <span class="text-xs icon-chevron-down"></span>
                </button>
              </li> -->
            </ol>
            <ol class="flex items-center h-full -mr-5">
              <li class="hidden h-full lg:block">
                <button
                  @click="$emit('open-language')"
                  class="relative flex items-center justify-center h-full px-2 text-white transition-colors duration-300 bg-zinc-400 aspect-square md:hover:bg-opacity-80"
                >
                  <div>
                    <div class="relative w-6 mx-auto mb-1">
                      <i class="relative z-0 icon-earth"></i>
                    </div>
                    <p class="text-xs font-medium">繁體中文</p>
                  </div>
                </button>
              </li>
              <li class="h-full">
                <button
                  @click="$store.commit('SetShopCartStatus', true)"
                  class="relative flex items-center justify-center h-full px-2 text-white transition-colors duration-300 aspect-square bg-primary md:hover:bg-opacity-80"
                >
                  <div>
                    <div class="relative w-6 mx-auto lg:mb-1">
                      <span
                        class="absolute -right-2 z-10 px-1 text-xs font-bold h-4 min-w-[16px] block transform rounded-full -top-1 bg-zinc-600 scale-[.9]"
                        >{{ shop_cart_count }}</span
                      >
                      <i class="relative z-0 icon-mail"></i>
                    </div>
                    <p class="hidden text-xs font-medium lg:block">詢價清單</p>
                  </div>
                </button>
              </li>

              <li class="block h-full lg:hidden">
                <button
                  @click="$emit('menu-action')"
                  class="relative flex items-center justify-center h-full px-2 transition-colors duration-300 bg-white text-primary aspect-square md:hover:bg-opacity-80"
                >
                  <i
                    v-show="!menu_status"
                    class="relative text-2xl icon-menu"
                  ></i>
                  <i
                    v-show="menu_status"
                    class="relative text-2xl icon-close"
                  ></i>
                </button>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div
        :class="shop_cart_dialog ? 'translate-y-full' : ''"
        class="absolute bottom-0 left-0 right-0 z-0 text-white transition-all duration-300 transform bg-primary"
      >
        <div
          class="w-full max-w-[1440px] px-5 mx-auto xl:px-0 sm:px-10 py-4 relative flex items-center md:justify-center justify-between"
        >
          已將商品加入詢價清單！
          <span
            @click="OpenShopCart"
            class="block ml-2 text-xs font-medium underline cursor-pointer"
            >查看詢價清單</span
          >

          <button
            @click="CloseShopCartDialog"
            class="absolute hidden text-xl transform -translate-y-1/2 md:block xl:right-0 sm:right-10 right-5 top-1/2"
          >
            <i class="icon-close"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AnnouncementMarquee } from "@/gsap/AnnouncementMarquee";
export default {
  name: "MainHeader",
  data() {
    return {
      menu_list: [
        {
          title: "產品列表",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "關於我們",
          sub_title: "About Us",
          link: "/about",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "影音專區",
          sub_title: "Videos",
          link: "/video",
        },
        {
          title: "聯絡我們",
          sub_title: "Contact",
          link: "/contact",
        },
        // {
        //   title: "會員中心",
        //   sub_title: "Member",
        //   link: "/account/login",
        // },
      ],
      hover_link_index: -1,
      drop_down: false,
      dialog_timer: null,
      announcement_gsap: null,
    };
  },
  props: {
    menu_status: {
      require: true,
      type: Boolean,
    },
  },
  methods: {
    OpenShopCart() {
      this.$store.commit("SetShopCartStatus", true);
    },
    CloseShopCartDialog() {
      this.$store.commit("SetShopCartDialog", false);
    },
    OpenSearchDialog() {
      this.$store.commit("SetSearchDialog", true);
    },
    CloseMenu() {
      if (this.menu_status) {
        this.$emit("menu-action");
      }
    },
  },
  computed: {
    shop_cart_dialog() {
      return this.$store.state.shop_cart_dialog;
    },
    shop_cart_data() {
      return this.$store.state.shopcart_module.shopcart;
    },
    shop_cart_count() {
      let count = 0;
      this.shop_cart_data.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
  watch: {
    shop_cart_dialog() {
      if (this.shop_cart_dialog) {
        this.dialog_timer != null ? clearTimeout(this.dialog_timer) : "";
        this.dialog_timer = setTimeout(() => {
          this.CloseShopCartDialog();
        }, 5000);
      }
    },
  },
  mounted() {
    if (this.announcement_list.length > 1) {
      this.announcement_gsap = new AnnouncementMarquee(
        this.$refs.AnnouncementMarquee
      );
      this.announcement_gsap.setup();
    }
  },
};
</script>
