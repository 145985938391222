<template>
  <footer class="relative z-10 w-full overflow-x-hidden bg-zinc-200">
    <div
      class="flex flex-wrap items-stretch justify-between w-full max-w-screen-xl px-5 py-10 mx-auto lg:py-20 xl:px-0"
    >
      <div
        class="flex items-center justify-center w-full mb-10 lg:flex-1 lg:justify-start lg:w-auto lg:mb-0"
      >
        <img src="@/assets/img/logo.webp" class="block w-32" />
      </div>
      <div class="w-full mb-5 md:w-1/2 lg:w-auto md:mb-0">
        <div class="w-full md:w-auto md:mr-20">
          <p class="mb-2 text-sm font-bold">聯絡資訊</p>
          <ol>
            <li class="flex items-center mb-3">
              <p class="mr-3 text-sm font-semibold text-black">聯絡電話</p>
              <p class="text-sm font-medium text-zinc-700">
                {{ $GetColumn("company_phone") }}
              </p>
            </li>
            <li class="flex items-center mb-3">
              <p class="mr-3 text-sm font-semibold text-black">總廠地址</p>
              <p class="text-sm font-medium text-zinc-700">
                {{ $GetColumn("company_address") }}
              </p>
            </li>
            <li class="flex items-center mb-3">
              <p class="mr-3 text-sm font-semibold text-black">二廠地址</p>
              <p class="text-sm font-medium text-zinc-700">
                {{ $GetColumn("company_address_1") }}
              </p>
            </li>
            <li class="flex items-center mb-3">
              <p class="mr-3 text-sm font-semibold text-black">電子信箱</p>
              <p class="text-sm font-medium text-zinc-700">
                {{ $GetColumn("company_email") }}
              </p>
            </li>
            <li class="flex items-center mb-3">
              <p class="mr-3 text-sm font-semibold text-black">營業時間</p>
              <p class="text-sm font-medium text-zinc-700">
                {{ $GetColumn("company_time") }}
              </p>
            </li>
          </ol>
        </div>
      </div>
      <div class="w-full md:w-1/2 lg:w-auto">
        <div class="w-full pb-5 mb-5 border-b border-zinc-300">
          <p class="mb-2 text-sm font-bold">網站地圖</p>
          <ol class="flex flex-wrap items-center -mx-2 lg:-mx-4 md:flex-nowrap">
            <li
              v-for="(item, item_index) in menu_list"
              :key="`menu_${item_index}`"
              class="px-2 text-zinc-500 lg:px-4"
            >
              <router-link
                :to="item.link"
                class="text-sm font-medium transition-all duration-300 hover:text-primary"
              >
                {{ item.title }}
              </router-link>
            </li>
          </ol>
        </div>

        <div class="w-full pb-5 border-b border-zinc-300">
          <p class="mb-2 text-sm font-bold">社群平台</p>
          <ol class="flex items-center -mx-4">
            <li class="px-4 text-zinc-500">
              <a
                target="_blank"
                :href="$GetColumn('company_facebook')"
                class="flex items-center text-sm font-medium transition-all duration-300 hover:text-primary"
              >
                <span class="mr-2 icon-facebook"></span>
                Facebook
              </a>
            </li>
            <li class="px-4 text-zinc-500">
              <a
                target="_blank"
                :href="$GetColumn('company_instagram')"
                class="flex items-center text-sm font-medium transition-all duration-300 hover:text-primary"
              >
                <span class="mr-2 icon-youtube"></span>
                Youtube
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="w-full bg-zinc-200">
      <div
        class="flex flex-wrap items-center justify-center w-full max-w-screen-xl px-5 py-4 mx-auto text-black md:justify-between xl:px-0"
      >
        <p class="text-xs">©2024 {{ $GetColumn("company_name") }}</p>

        <p class="text-xs">
          DESIGN BY
          <a
            class="ml-2 text-black opacity-50"
            href="https://www.yongxin-design.com"
            target="_blank"
            >泳欣事業有限公司</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter",
  data() {
    return {
      menu_list: [
        {
          title: "商品列表",
          sub_title: "Products",
          link: "/collections",
        },
        {
          title: "關於我們",
          sub_title: "About Us",
          link: "/about",
        },
        {
          title: "最新消息",
          sub_title: "News",
          link: "/news",
        },
        {
          title: "影音專區",
          sub_title: "Videos",
          link: "/video",
        },
        {
          title: "聯絡我們",
          sub_title: "Contact",
          link: "/contact",
        },
        // {
        //   title: "非會員訂單查詢",
        //   sub_title: "Order Search",
        //   link: "/order/search",
        // },
      ],
    };
  },
  methods: {
    ScrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>