<template>
  <section class="relative z-10 w-full xl:mb-20">
    <div
      class="relative z-10 w-full max-w-screen-xl px-5 py-24 mx-auto bg-black xl:px-0"
    >
      <div class="relative z-10 w-full px-10">
        <header class="relative w-full mb-5 text-white">
          <p class="mb-1 text-4xl font-bold md:text-5xl">CONTACT US</p>
          <h3 class="font-bold md:text-xl">聯絡我們</h3>
        </header>

        <p class="mb-10 text-sm text-white">
          有任何商品規格、安裝、售後服務，或是合作、經銷洽談都歡迎與我們聯絡
        </p>
        <div>
          <router-link
            @mouseenter.native="link_hover = true"
            @mouseleave.native="link_hover = false"
            to="/contact"
            class="flex items-center justify-between max-w-full py-3 pl-6 pr-3 text-sm font-medium text-center text-white transition-all duration-300 border rounded-full w-44 bg-primary md:hover:bg-transparent md:hover:text-primary border-primary"
          >
            填寫表單

            <span
              :class="
                link_hover ? 'bg-primary text-white' : 'bg-white text-primary'
              "
              class="flex items-center justify-center w-6 h-6 transition-all duration-300 rounded-full"
            >
              <span class="text-xs icon-chevron-right"></span>
            </span>
          </router-link>
        </div>
      </div>

      <div class="absolute top-0 left-0 z-0 w-full h-full opacity-20">
        <img
          :src="$ImageUrl($GetColumn('contact_footer_image'))"
          class="object-cover w-full h-full"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactFooter",
  data() {
    return {
      link_hover: false,
    };
  },
};
</script>